.popup {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  width: 680px;
  top: calc(100% + 10px);
  z-index: 1;
  position: relative;

  .checkboxes {
    display: flex;
    flex-direction: column;
    margin: -8px 0;
    > * {
      margin: 8px 0;
    }
  }

  .devicesContainer {
    margin-left: 20px;
  }

  .minMaxRangeContainer {
    margin-left: 20px;
    width: 150px;

    .label {
      margin-bottom: -1px;
    }

    .minMaxRangeComponents {
      border: 1px solid var(--color4);
      border-radius: 12px;
      height: 45px;
      margin-bottom: 19px;
      padding-left: 10px;

      input {
        margin-top: 10px;
        width: 50px;
      }
    }
  }

  .actions {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    input {
      min-width: 184px;
    }

    input:read-only {
      background-color: var(--color5);
    }

    .buttonGroup {
      margin-top: auto;
      align-self: center;
      display: flex;
      flex-direction: column;

      button {
        margin-top: 8px;
      }
    }
  }
}

.popupMobile {
  display: flex;
  padding: 20px 16px;
  z-index: 50;
  left: 2px;
  top: 45px;

  .popupContent {
    flex-direction: row;
    left: 0;
    z-index: 51;
    position: relative;
    padding: 5px;

    .minMaxRangeContainer {
      width: 150px;
      text-transform: uppercase;
      font-size: 16px;

      .minMaxRangeComponents {
        border: 1px solid var(--color4);
        border-radius: 12px;
        height: 45px;
        margin-bottom: 19px;
        padding-left: 10px;

        input {
          margin-top: 10px;
          width: 50px;
        }
      }
    }

    .fillCheckboxesTitle {
      text-transform: uppercase;
      font-size: 16px;
    }

    .fillCheckboxes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      border: 1px solid var(--color2);
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 5px;

      span {
        border: 1px solid var(--color8);
      }
    }

    .actions {
      input {
        background-color: white;
      }
      input :not(.DayPickerInput) {
        width: 100%;
        max-width: 100%;
      }
    }

    .buttonGroup {
      button {
        margin-right: 10px;
      }
    }
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .popupMobile {
    min-width: 60vw;
  }
}

@media (min-width: 750px) and (max-width: 800px) {
  .popupMobile {
    min-width: 55vw;
  }
}

@media (min-width: 600px) and (max-width: 750px) {
  .popupMobile {
    min-width: 80vw;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .popupMobile {
    min-width: 85vw;
  }
}

@media (max-width: 680px) {
  .popupMobile {
    padding: 5px 2px;
    top: calc(100% + 30px);

    .actions {
      margin-left: 0px;
      align-items: flex-start;

      input {
        min-width: 184px;
      }

      input:read-only {
        background-color: var(--color5);
      }

      .buttonGroup {
        margin-top: auto;
        display: flex;
        flex-direction: row;

        button {
          margin-top: 8px;
        }
      }
    }
  }
}
