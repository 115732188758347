.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
    animation: rotate 0.8s linear infinite;
    max-width: 180px;
    stroke: var(--color10);
  }
}

.small {
  width: 50px;
}

.overlay {
  top: 0;
  left: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: var(--color5);
  opacity: 0.8;
  svg {
    position: absolute;
    top: 20%;
    left: 50%;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
