.root {
  width: 221px;
  overflow: visible;
  background-color: var(--color2);
  color: var(--color5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 200;

  a {
    min-height: 24px;
    min-width: 141px;
    border-radius: 12px;
    background-color: var(--color2);
    color: var(--color5);
    text-decoration: none;

    display: flex;
    align-items: center;
    padding: 6px 13px;
    margin-right: 8px;
    margin-left: 18px;
    margin-bottom: 16px;

    outline: none;

    svg {
      flex-shrink: 0;
    }

    &:hover,
    &.active {
      background-color: black;
      svg {
        path {
          fill: var(--color3);
        }
        circle {
          stroke: var(--color3);
        }
      }
    }

    svg {
      margin-right: 6px;
    }
  }
}

.logo {
  width: 50px;
  align-self: center;
  margin-top: 24px;
}

.section {
  font-size: 12px;
  text-transform: uppercase;
  margin: 16px;
}

.sectionMain {
  @extend .section;
  margin-top: 20px;
}

.sectionHelp {
  @extend .section;
  margin-top: 46px;
}

.userSection {
  display: flex;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;

  .userName {
    font-size: 14px;
    margin: 16px 0;
  }

  button {
    font-size: 14px;
    background-color: var(--color2);
    border-radius: 12px;
    padding: 6px 13px;

    color: inherit;
    font-family: inherit;
    border: none;
    outline: none;

    &:hover {
      background: black;
    }
  }
}

@media (max-width: 550px) {
  .root {
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 100%;
  }
}
