div.rolesDialogHidden {
  display: none;
}

div.rolesDialog {
  button:first-child {
    display: none;
  }

  div.message {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .checkboxrow {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 20px;

    input[type="checkbox"] {
      margin-right: 7px;
    }

    select {
      min-width: 200px;
      width: 100%;
      background-color: rgb(255, 255, 255);

      option {
        background-color: rgb(255, 255, 255);
      }

      option:checked {
        background-color: var(--color3);
      }

      option:hover {
        background-color: var(--color3);
      }
    }

    select[multiple]:focus {
      option:checked {
        background: linear-gradient(0deg, var(--color3) 0%, var(--color3) 100%);
      }
    }
  }
}
