.paddingLeft {
  padding-left: 10px;
}

.title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 2px solid var(--color6);
  padding: 10px 18px;
  padding-right: 0;
  margin-bottom: 18px;
  justify-content: space-between;
  height: 40px;

  div:first-child {
    width: 5%;
  }

  div:nth-child(2) {
    width: 15%;
    justify-content: start;
  }

  div:nth-child(3) {
    width: 25px;
    padding-top: 7px;
    justify-content: left;
    justify-items: left;
  }

  div:nth-child(4) {
    width: 15%;
    text-align: center;
    color: var(--color14);
  }

  div:nth-child(5) {
    width: 25%;
    justify-content: center;
    justify-items: center;
    text-align: center;
    color: var(--color14);
  }

  .printAndDeleteContainer {
    display: flex;
    margin-left: 10%;
    flex-direction: row;
    flex-wrap: nowrap;

    .printButtonContainer {
      width: 150px;
      justify-content: flex-end;
    }

    .deleteIconContainer {
      width: 20px;
      padding-top: 8px;
    }
  }
}

.measurementsContainer {
  width: 100%;
  padding-bottom: 30px;
  margin-right: 0;

  table {
    border-collapse: collapse !important;
    table-layout: auto;
    width: 100%;
    color: var(--color8);

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          background-color: var(--color1);
        }
        &.hovered {
          background-color: var(--color1);
        }
      }
    }

    th,
    td {
      color: var(--color14);
      font-size: 12px;
      padding: 0 12px;
      text-align: right;
    }

    th {
      user-select: none;
      cursor: pointer;
    }

    td {
      border-top: 1.8px solid var(--color6);

      .species {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .speciesText {
        margin-left: 5px;
      }
    }

    th {
      font-weight: bold;
    }
  }
}

.measurementsContainerReport {
  width: 100%;
  margin-top: 50px;
  padding-bottom: 30px;

  .coordinates {
    font-size: 18px;
    padding-bottom: 10px;
  }

  table {
    border-collapse: collapse !important;
    table-layout: auto;
    width: 100%;
    color: var(--color8);

    th,
    td {
      color: var(--color14);
      font-size: 12px;
      padding-top: 5px;
      text-align: left;
    }

    th {
      font-size: 16px;
      font-weight: bold;
    }

    td {
      border-top: 1.8px solid var(--color6);

      .species {
        vertical-align: top !important;
        margin-top: 10px;
      }
    }

    th {
      font-weight: bold;
    }
  }
}

.printLink {
  padding-left: 10px;
  cursor: pointer;
}

.groupType {
  max-width: 200px;
  flex-grow: 1;
  text-align: center;
  color: var(--color14);
}

.watermark {
  height: 100%;
  min-height: 100%;
  background-color: var(--color5);
}

.reportUserInfo {
  margin-top: 0.5cm;

  table {
    border: 0;
    width: 100%;

    td {
      padding-left: 13px;
      padding-right: 13px;
    }

    td:last-child {
      text-align: right;
    }
  }
}

.reportComment {
  padding-top: 30px;
  :first-child {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
