.cta {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  outline: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 36px;

  height: 60px;

  background-color: var(--color10);
  color: var(--color4);

  border: none;
  border-radius: 12px;

  &:enabled:hover {
    background-color: #f2c23a;
  }

  &:enabled:focus,
  &:enabled:active {
    filter: brightness(0.94);
  }

  &.secondary {
    background: none;
    box-shadow: inset 0 0 0 2px var(--color4);

    &:enabled:hover {
      box-shadow: none;
      background-color: #f2c23a;
    }
  }

  &.danger {
    color: var(--color5);
    background-color: var(--color7);

    &:enabled:hover {
      background-color: #f65555;
    }
  }

  &:disabled {
    background-color: var(--color6);
    color: var(--color8);
    box-shadow: none;
  }

  &.medium {
    font-size: 16px;
    padding: 10px 24px;
    height: 40px;
  }

  &.small {
    font-size: 16px;
    height: 28px;
    padding: 4px 12px;
    border-radius: 6px;
  }
}

.ctaLink {
  appearance: none;
  display: inline-block;
  font-size: 24px;
  text-decoration: none;
  color: unset;
  padding: 0;
  padding-bottom: 5px;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 1px var(--color3), inset 0 -1px var(--color3);
  background: none;
  outline: none;

  &.navigation {
    box-shadow: none;
    &.active {
      box-shadow: 0 1px var(--color3), inset 0 -1px var(--color3);
    }
  }

  &:focus,
  &:hover {
    box-shadow: 0 2px var(--color3), inset 0 -2px var(--color3);
    cursor: pointer;
  }

  &:active {
    filter: brightness(0.94);
  }

  &.medium {
    font-size: 16px;
  }
}
