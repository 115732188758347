.dashboardSection {
  h1 {
    font-size: 32px;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .dashboardTop {
    max-width: 1000px;
    margin-right: 32px;
    padding: 12px 0;
    border-top: 2px solid var(--color6);
    border-bottom: 2px solid var(--color6);
    &.navigation {
      & > * {
        margin-right: 72px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .dashboardSection {
    .dashboardTop {
      margin-right: 4px;
    }
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .dashboardSection {
    .dashboardTop {
      margin-right: 15px;
    }
  }
}
