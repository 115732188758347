button.mapToggle {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
  z-index: 10;
  background-color: var(--color1);

  img {
    width: 24px;
    height: 24px;
  }

  @media all and (min-width: 1550px) {
    visibility: hidden;
  }
}

.mapBackground .map .mapControlls {
  position: relative;
  button.revert {
    appearance: none;
    position: absolute;
    z-index: 100;
    top: 8px;
    right: 10px;
    padding: 4px 8px;
    border: none;
    outline: none;
    font-size: 12px;
    border-radius: 8px;
    min-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--color6);
    &:hover,
    &:focus {
      background-color: #f2c23a;
    }
    &:active {
      filter: brightness(0.94);
    }
  }
  button.save {
    position: absolute;
    z-index: 100;
    top: 40px;
    right: 10px;
  }
}

@media all and (min-width: 1550px) {
  .mapBackground {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 512px;

    .map {
      width: 100%;
      height: 100%;
    }
  }
}

@media not all and (min-width: 1550px) {
  .mapBackground {
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    top: 4px;
    bottom: 0;
    left: -36px;
    right: 0;
    background-color: change-color($color: #999999, $alpha: 0);
    transition: background-color 0.2s ease-out, visibility 0.2s;

    &.open {
      visibility: visible;
      background-color: change-color($color: #999999, $alpha: 0.3);
    }

    .map {
      position: absolute;
      visibility: hidden;
      top: 0;
      bottom: 0;
      right: -512px;
      width: 512px;
      max-width: 100%;
      transition: left 0.2s ease-out, right 0.2s ease-out, visibility 0.2s;
    }

    &.open .map {
      visibility: visible;
      right: 0;
    }
  }
}
