.offsets {
  .offsetsTitle {
    margin-top: 16px;
  }

  .grid {
    margin-top: 32px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 96px 1fr 1fr 1fr;
    grid-auto-rows: 36px;
    grid-template-areas:
      "  .  | none | none | none"
      "none | none | none | none"
      "...";
    color: var(--color14);
    justify-items: end;
    align-items: center;

    .positive {
      color: var(--color15);
    }

    .negative {
      color: var(--color16);
    }

    .compound {
      justify-self: start;
      font-weight: bold;
    }
    .glutenOffset {
      grid-column-start: span 2;
    }
  }
}
