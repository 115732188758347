.checkboxLabel {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.hiddenCheckbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.customCheckbox {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid var(--color4);
  border-radius: 20px;
  background-color: var(--color1);
  transition: all 0.2s ease;
  font-size: 14px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.checked {
  background-color: var(--color3); // Esim. sininen, kun valittu
  color: var(--color2);
  border-color: var(--color4);
}
