.header {
  background: var(--color2);
  height: 166px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.logo {
  margin-left: 100px;
  height: 50%;
  width: auto;
}

.logoText {
  margin-right: 100px;
  height: 50%;
  width: auto;
}

.bigger {
  height: 60% !important;
}

.center {
  justify-content: center;
}

.main {
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;
  background-color: var(--color5);
  padding: 32px 60px;
}

@media (max-width: 550px) {
  .main {
    margin-top: 0;
    padding: 20px 20px;
  }

  .header {
    height: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .logo {
    margin-left: 0px;
    height: 30%;
    width: auto;
  }

  .logoText {
    margin-right: 0px;
    height: 30%;
    width: auto;
  }
}
