button.button {
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 26px;
  width: 200px;
  margin: 4px 0;
  padding: 0;
  padding-left: 24px;
  background: none;
  border: none;
  outline: none;

  &:enabled:focus {
    background-color: var(--color10);
    filter: brightness(1.04);
  }

  &:enabled:hover {
    background-color: var(--color10);
    filter: brightness(1);
  }
  &:enabled:active {
    background-color: var(--color10);
    filter: brightness(0.94);
  }

  &:disabled {
    background-color: var(--color6);
    color: var(--color8);
  }
}

.popup {
  top: calc(100% - 12px);
  left: calc(100% - 12px);
  padding: 16px 0;
}

.popupAccountPage {
  @extend .popup;
}

.popupLoginPage {
  @extend .popup;
}

.popupDetailsPage {
  @extend .popup;
}

.popupListPage {
  @extend .popup;
}

@media (max-width: 1024px) {
  .popupLoginPage {
    left: -70%;
    top: 22px;
  }

  .popupListPage {
    left: -25%;
    top: 22px;
  }
}

@media (max-width: 387px) {
  .popupListPage {
    left: 0;
    top: 22px;
  }
}
