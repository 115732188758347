.pageSection {
  h1 {
    font-size: 32px;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .pageTop {
    max-width: 1000px;
    min-height: 88px;
    margin-right: 32px;
    padding: 12px 0;
    border-top: 2px solid var(--color6);
  }
}
.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 50px;
  background-color: var(--color1);
  padding: 10px;
  padding-top: 54px;
}

.gridCard {
  background-color: rgba(255, 255, 255, 0.8);
  width: 500px;
  height: 309px;
  padding: 20px;
  margin: 30px;
  text-align: left;
  font-family: "Karmilla";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: var(--color2);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}
.Spinner {
  z-index: 999;
  position: absolute;
  flex: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.card {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.2rem;
  min-width: 14rem;
  display: flex;
  font-family: "Karmilla";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--color2);
  margin-top: 2rem;
  width: 47rem;
  height: 19rem;

  &__body {
    margin: 1rem;
    flex-grow: 1;
    width: 20rem;
  }

  &__body2 {
    background: var(--color6);
    padding: 3rem 0 0 0;
    text-align: center;
    flex-grow: 1;
    line-height: 10px;
    width: 9rem;
  }

  &__title {
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
  }
  &__description {
    line-height: 1.2rem;
  }
  &__price {
    font-size: 1.4rem;
    margin-top: 0.6rem;
  }

  &__valid {
    font-weight: 700;
    padding-top: 18px;
  }

  &__table {
    font-weight: 400;
    list-style-type: disc;
    padding-left: 0px;
  }

  li {
    list-style: none;
    font-size: 20px;
    line-height: 28px;
  }

  li span {
    font-size: 14px;
  }

  li:before {
    content: "·";
    font-size: 120px;
    color: var(--color10);
    vertical-align: middle;
    line-height: 20px;
  }

  &__input {
    width: 1.8rem;
    background: var(--color5);
    border-radius: 4px;
    padding: 10px 6px 10px 6px;
    text-align: center;
  }

  &__label {
    background: var(--color12);
    padding: 10px 6px 10px 6px;
    border-radius: 4px;
  }

  &__productcode {
    font-weight: 400;
    font-size: 12px;
  }

  &__btn {
    border: none;
    background: var(--color10);
    border-radius: 12px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    padding-top: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    width: 60%;
    padding: 1rem;
  }
}
