form.export {
  display: flex;
  flex-direction: column;
  align-items: center;

  fieldset {
    border: none;
    padding: 0;
    margin-top: 4px;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;

    & > * {
      margin: 6px 0;
    }
  }
}

ul.averages {
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
  max-width: 445px;
  width: 100%;

  li {
    min-height: 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color6);

    span {
      margin-left: auto;
    }
  }
}

.averagesDisabled {
  display: flex;
  align-self: stretch;
  flex: 1;
  align-items: center;
  margin-left: -24px;
  padding-left: 16px;

  img {
    width: 16px;
    margin-right: 8px;
    display: block;
  }

  &:hover .averagesError {
    display: flex;
  }

  .averagesError {
    display: none;
    position: absolute;
    top: -16px;
    left: 16px;
    padding: 4px 8px;
    max-width: 512px;
    width: max-content;
    border: 1px solid var(--color7);
    border-radius: 8px;
    background-color: var(--color1);
    color: var(--color7);
  }
}

.createGroupDialog {
  width: 80%;
  margin-bottom: 50px;
  .requiredNumber {
    font-size: 16px;
    text-transform: uppercase;
  }

  .requiredNumberInput {
    padding: 12px 24px;
    padding-right: 0;
    border-radius: 12px;
  }
}
