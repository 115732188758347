.titleGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  h1 {
    margin: 0;
    font-size: 32px;
    color: var(--color9);
  }
}

.ctaGroup {
  display: flex;
  align-items: flex-end;
  margin: 0 -32px;

  > * {
    margin: 0 32px;
  }
}

.success {
  font-size: 24px;
}
