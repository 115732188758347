.description {
  display: flex;
  align-items: center;
  font-size: 24px;
}

h2.instructionsTitle {
  font-weight: normal;
}

ol.instructionsList {
  padding-left: 32px;

  a {
    text-decoration: none;
  }
}

.error {
  display: flex;
  align-items: center;
  max-width: 1000px;
  font-weight: bold;
  color: var(--color7);

  img {
    margin-right: 16px;
  }
}

.liftUp {
  margin-top: 0px !important;
  padding: 0px !important;
}

.collapseCalculator {
  min-height: 690px;
}

form.form {
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 1000px;
  overflow: visible !important;
  & > fieldset {
    display: contents;
  }

  fieldset.main {
    background-color: var(--color5);
    padding: 24px;
    margin: 0;
    border: none;

    label {
      text-transform: uppercase;
      color: var(--color8);
    }

    .calculatorHasOil {
      grid-template-columns: repeat(13, 1fr) !important;
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button.reset {
        margin-right: 5px;
      }
    }

    .pullLeft {
      margin-right: 15px;
    }

    .valuesContainer {
      display: grid;
      grid-template-columns: 5fr 5fr 1fr;
      max-width: 230px;
      margin-top: 10px;
      margin-bottom: 30px;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid var(--color12);

      .formula {
        text-align: right;
        margin-right: 5px;
      }

      .title {
        font-weight: bold;
        padding-bottom: 10px;
      }

      .fullwidth {
        grid-column: span 3;
      }

      button {
        margin-top: 20px;
        width: 100%;
      }

      .value {
        text-align: right;
      }
    }

    .calculator {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      padding-bottom: 5px;
      margin-right: -10px;
      align-items: center;

      .darker {
        background-color: var(--color13);
      }

      label.titleHasOil {
        grid-column: span 3 !important;
      }

      label.title {
        grid-column: span 2;
      }

      label.header {
        margin-bottom: 10px;
        text-align: center;
        color: #b7b7b7;
        margin: 5px;
        &.bold {
          color: var(--color2);
          font-weight: bold;
        }
      }

      .subheader {
        text-align: center;
        padding-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 1px;
        padding-right: 1px;
      }

      .sampleNumberColumn {
        text-align: center;
      }

      .sampleNumberTitle {
        padding-bottom: 10px;
      }

      .fieldColumn {
        text-align: center;
        input {
          appearance: none;
          text-align: right;
          height: 24px;
          width: 40px;
          padding: 2px 4px;
          margin: 3px;

          border: none;
          border: 1px solid var(--color12);
          border-radius: 6px;
          outline: none;

          &:focus {
            border-color: black;
          }
        }
      }
    }

    .corrections {
      display: grid;
      grid-template-columns: minmax(128px, 1fr) 92px 92px 92px;
      grid-auto-rows: 40px;
      gap: 0 32px;
      align-items: center;

      div.addNewIBA {
        font-weight: bold;
        font-size: 20px;
      }

      label.header {
        text-align: right;
        color: #b7b7b7;
        &.total {
          color: var(--color2);
          font-weight: bold;
        }
      }

      label.gluten {
        grid-column: span 2;
        small {
          display: block;
        }
      }

      input {
        appearance: none;
        text-align: right;
        height: 24px;
        border: 1px solid var(--color12);
        border-radius: 6px;
        padding: 4px 8px;
        margin: 0px;
        &:disabled {
          cursor: default;
          color: var(--color2);
          background-color: var(--color6);
        }
        &:not([type="number"]) {
          padding-right: 16px;
        }
        &:focus {
          outline: none;
          border-color: var(--color2);
        }
        &:invalid {
          border-color: var(--color7);
        }
      }
    }

    .actions {
      display: flex;
      overflow: hidden;
      margin-top: 24px;

      & > * {
        margin: 0 32px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }
}

.linkButton {
  margin: 40px 0 20px 0px;

  img {
    margin-left: 8px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }

  &.open img {
    transform: rotate(180deg);
  }
}
