.root {
  position: relative;
  z-index: 200;
  display: inline-block;

  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 6px 10px;

    min-width: 124px;
    min-height: 36px;

    background: var(--color5);
    border: 1px solid var(--color4);
    border-radius: 30px;

    font-size: 16px;
    outline: none;

    &:disabled {
      background: var(--color1);
    }

    &.opened {
      background: var(--color10);

      &:hover {
        border-color: var(--color10);
      }
    }
  }

  .popup {
    position: absolute;
    z-index: -50;

    background: var(--color1);
    border: 1px solid var(--color4);
    border-radius: 4px;
  }
}

@media (max-width: 1024px) {
  .root {
    .popup {
      z-index: 1;
    }
  }
}
