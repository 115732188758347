.steps {
  display: flex;
  justify-content: center;
  .step {
    margin: 0 -8px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    p {
      max-width: 200px;
    }
  }
}
