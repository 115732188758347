.root {
  max-width: 640px;
  font-size: 24px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  input,
  textarea,
  select {
    appearance: none;
    min-height: 64px;
    padding: 12px 24px;
    background-color: var(--color5);
    font: unset;
    font-size: 24px;

    border: none;
    border-radius: 12px;
    --border-color: var(--color4);
    box-shadow: 0 0 0 1px var(--border-color);
    box-sizing: border-box;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 3px var(--border-color);
    }

    &:disabled {
      color: var(--color8);
      background-color: var(--color6);
    }

    &:read-only {
      background-color: var(--color1);
    }

    &.blured:invalid {
      --border-color: var(--color7);
    }
  }

  .errors {
    font-size: 16px;
    text-transform: none;
    margin-top: 4px;
    margin-bottom: -24px;
    min-height: 20px;
    color: var(--color7);
  }
}

.root.medium {
  font-size: 16px;
  margin-bottom: 16px;

  input,
  textarea,
  select {
    font-size: 16px;
    min-height: 48px;
  }

  .errors {
    font-size: 12px;
  }
}
