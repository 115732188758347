label.root {
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  user-select: none;

  input {
    appearance: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border: 2px solid var(--color4);
    border-radius: 3px;
    margin: 0;
    outline: none;
    background-position: center;
    background-repeat: no-repeat;

    &:active,
    &:focus {
      background-color: var(--color21);
    }

    &:checked {
      background-color: var(--color3);
      background-image: url(../images/check.svg);
      background-position: center;
      background-repeat: no-repeat;

      &:active,
      &:focus {
        background-color: adjust-color($color: #f6c63d, $lightness: -15%);
      }
    }

    &:indeterminate {
      background-image: url(../images/checkIndeterminate.svg);
    }
  }
  .content {
    margin-left: 14px;
  }
}
