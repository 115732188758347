.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;

  &.loading {
    opacity: 0.4;
    cursor: progress;
    * {
      cursor: progress !important;
    }
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .right {
    display: flex;
    flex-direction: column;

    .actionGroup {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: auto;
      > :first-child {
        margin-right: 14px;
      }
    }

    .filterDescription {
      height: 28px;
      display: flex;
      align-items: center;
      margin-top: 8px;

      strong {
        text-transform: lowercase;
      }
      button {
        text-wrap: nowrap;
        margin-left: 20px !important;
        height: auto;
      }
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.main {
  flex-grow: 1;
  display: flex;
  min-height: 0;

  .tableContainer {
    flex-basis: 1000px;
    max-width: 1000px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
  }
}

.vadWarning {
  width: 100%;
  margin-top: 4px;
  color: var(--color7);
  display: flex;
  align-items: center;
  img {
    display: block;
    margin-right: 8px;
  }
}

.comprehensivenessExplanation {
  display: flex;
  align-items: center;
}

.mobileControls {
  display: grid;
  grid-template-columns: 2fr 1fr;
  z-index: 40;
  background-color: var(--color1);

  .filterDescription {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .resetButton {
    margin-left: 5px;
    padding: 10px;
    height: auto !important;
  }

  .colspan2 {
    grid-column: span 2;
  }

  .gridItemRight {
    justify-self: end;
  }

  .gridItemBottom {
    align-content: end;
  }

  .filterDescriptionMarginTop {
    margin-top: 5px;
  }
}

@media (max-width: 400px) {
  .main {
    .tableContainer {
      margin-right: 4px;
    }
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .main {
    .tableContainer {
      margin-right: 15px;
    }
  }
}

@media (min-width: 600px) and (max-width: 750px) {
  .comprehensivenessExplanation {
    font-size: 16px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .comprehensivenessExplanation {
    font-size: 15px;
  }
}

@media (min-width: 345px) and (max-width: 399px) {
  .comprehensivenessExplanation {
    font-size: 14px;
  }

  .mobileControls {
    .right {
      width: 100%;

      .actionGroup {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 360px) {
  .comprehensivenessExplanation {
    font-size: 13px;
  }
}
