.root {
  margin-top: 12px;
  padding: 4px 8px;
  background-color: var(--color5);
  min-width: 864px;
  overflow: auto;

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    color: var(--color8);

    th,
    td {
      height: 48px;
      padding: 0;
      text-align: center;
      font-weight: normal;
      padding: 0 12px;

      &.groupName {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.check {
        width: 48px;
        padding: 0;
        label {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      &.attachmentColumn {
        width: 20px;
      }

      &.hidden {
        display: none;
      }

      &.comprehensivenessColumn {
        width: 25px !important;
        padding: 0;
        margin: 0;
        text-align: center !important;
        img {
          padding-top: 3px;
        }
      }

      &.species {
        width: 120px;
        .speciesContainer {
          display: flex;
          align-items: center;
          .speciesText {
            flex-grow: 1;
            color: black;
          }
        }
      }

      &.comment {
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.date {
        width: 120px;
      }

      &.compound {
        font-size: 12px;
        width: 60px;
        padding: 0 8px;
      }
    }

    th {
      user-select: none;
      cursor: pointer;
    }

    td {
      border-top: 1.8px solid var(--color6);
    }

    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: var(--color1);
        }
        &.hovered {
          background-color: var(--color1);
        }
      }
    }
  }
}

th.compound {
  text-align: right !important;
  white-space: nowrap;
}

.noOrd {
  visibility: hidden;
}

.ordAsc {
  visibility: visible;
  transform: rotate(180deg);
}

.ordDesc {
  visibility: visible;
}
