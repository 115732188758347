.toggle {
  cursor: pointer;
  height: 24px;
  margin-top: 20px;
  margin-bottom: -44px;
  margin-left: auto;
  margin-right: 28px;
  z-index: 10;
  &:hover {
    svg {
      fill: var(--color2);
    }
  }
}

@media (max-width: 750px) {
  svg {
    margin-top: -8px;
  }
}
