.root {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 400;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: change-color($color: #999999, $alpha: 0.3);

  .modal {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color5);
    border-radius: 4px;
    max-width: 522px;
    max-height: 80%;
    overflow-y: auto;
    padding: 8px;
    margin: 0 8px;

    svg.closeButton {
      display: block;
      padding: 8px;
      align-self: flex-end;
      fill: var(--color9);
      cursor: pointer;
    }

    h3.title {
      text-align: center;
      margin-top: 14px;
      margin-bottom: 48px;

      &.noContent {
        margin-top: 40px;
        margin-bottom: 4px;
      }
    }

    .buttonGroup {
      display: flex;
      margin-top: 24px;
      margin-bottom: 30px;
      & > * {
        margin: 0 6px;
      }
    }
  }
}
