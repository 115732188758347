.root {
  margin-top: -6px;
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--color7);
  display: flex;

  img {
    margin-right: 26px;
  }
}
