.root {
  display: flex;
  height: 100vh;
  align-content: stretch;

  main {
    overflow: auto;
    flex-grow: 1;
    padding: 0;
    padding-left: 36px;
  }
}

@media (max-width: 400px) {
  .root {
    main {
      padding-left: 4px;
    }
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .root {
    main {
      padding-left: 15px;
    }
  }
}
