.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.root {
  flex-grow: 1;
  display: flex;
  min-height: 0;

  .detailsContainer {
    flex-basis: 1000px;
    max-width: 1000px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;

    .details {
      overflow: auto;
      background-color: var(--color5);
      margin-top: 12px;
      padding: 8px 16px;
    }
  }

  .backgroundImageDJ {
    background-image: url("../../images/DJ/dj_watermark.png") !important;
  }

  .detailsContainerReport {
    margin: 0;
    padding: 1cm;
    padding-top: 0;
    height: 26.9cm;
    background-image: url("../../images/logo_report.svg");
    background-repeat: no-repeat;
    background-size: 20cm 20cm;
    background-position: center;
    align-content: center;

    .detailsReport {
      background-color: transparent;
      margin-top: 12px;
      padding: 8px 16px;
    }
  }

  @media not all and (min-width: 1550px) {
    position: relative;
  }
}

.title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid var(--color6);
  padding: 10px 18px;
  margin-bottom: 18px;
  padding-right: 0px;
  margin-right: 0;

  h3 {
    font: unset;
    margin: 0;
    margin-left: 24px;
    font-size: 24px;
  }

  .info {
    max-width: 200px;
    flex-grow: 1;
    text-align: center;
    color: var(--color14);
  }

  svg {
    cursor: pointer;
    margin-left: auto;
    flex-shrink: 0;

    &:hover,
    &:focus,
    &:active {
      path {
        fill: var(--color7);
      }
    }
  }
}

.body {
  display: flex;
  justify-content: flex-start;

  > div:first-child {
    margin-right: 10px;
  }

  > div:last-child {
    .attachmentContainer {
      margin-top: 30px !important;

      svg {
        margin-bottom: -3px;
        margin-left: 5px;
      }

      input {
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
      }
    }
  }
}

.bodyReport {
  display: flex;
  justify-content: space-between;
  margin-left: 1.5cm;
  margin-right: 1.5cm;
  align-content: center;
}

.dataReport {
  flex-grow: 1;
  padding-left: 0;
  margin: 0;

  small {
    font-size: 12px;
    font-weight: normal;
    color: var(--color14);
    display: block;
  }

  strong {
    font-weight: bold;
    color: var(--color8);
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 2px solid var(--color6);

      :last-child {
        justify-self: flex-end;
        margin-left: auto;
      }
    }
  }
}

.data {
  flex-grow: 1;
  padding-left: 62px;
  max-width: 480px;

  small {
    font-size: 12px;
    font-weight: normal;
    color: var(--color14);
    display: block;
  }

  strong {
    font-weight: bold;
    color: var(--color8);
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 2px solid var(--color6);

      :last-child {
        justify-self: flex-end;
        margin-left: auto;
      }
    }
  }
}

.iconCompound {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-right: 42px;
  border-radius: 50%;
}

.iconProtein {
  @extend .iconCompound;
  background-color: var(--color12);
}

.iconMoisture {
  @extend .iconCompound;
  background-color: var(--color17);
}

.iconCarbohydrate {
  @extend .iconCompound;
  background-color: var(--color18);
}

.iconOil {
  @extend .iconCompound;
  background-color: var(--color19);
}

.detailsButton {
  margin: 40px 0 20px -50px;

  img {
    margin-left: 8px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }

  &.open img {
    transform: rotate(180deg);
  }
}

.percentageContainer {
  text-align: right;
}

.input {
  min-width: 274px !important;

  textarea {
    resize: none;
  }
}

.customTitle {
  text-transform: uppercase;
  font-size: 16px;
}

.errormessage {
  font-size: large;
  margin-top: 10em;
  text-align: center;

  code {
    color: red;
  }
}
