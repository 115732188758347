.resources {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    color: unset;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    margin-top: 24px;

    img {
      margin-left: 8px;
    }
  }
}
