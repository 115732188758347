label.language {
  max-width: 640px;
  margin: 56px 0;
  font-size: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

form.form {
  margin-bottom: 86px;
}

.terminateButton {
  color: var(--color7);
  margin-top: 48px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.terminate {
  max-width: 640px;
  margin: 16px 0;
}
