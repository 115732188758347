@font-face {
  font-family: "Karmilla";
  src: url(../resources/karmilla-regular.woff2) format("woff2");
}

@font-face {
  font-family: "Karmilla";
  src: url(../resources/karmilla-bold.woff2) format("woff2");
  font-weight: bold;
}

$font-family: Karmilla, Roboto, "Open Sans", Arial, Helvetica, sans-serif;

body {
  margin: 0;
  font-family: $font-family;
  color: var(--color2);
  background-color: var(--color1);
}

button {
  font-family: $font-family;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

input {
  font-family: $font-family;
}

@import "_cta.scss";
@import "_dashboard.scss";
@import "_date.scss";
@import "_map.scss";
