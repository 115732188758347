.root {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--color8);
  margin-top: 5px;

  :nth-child(2) {
    margin-right: 30px;
  }

  @media (max-width: 370px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
}

.offset {
  display: flex;
  align-items: center;
  margin-right: 24px;

  button {
    appearance: none;
    border: none;
    padding: 0;
    &:not(:first-child) {
      margin-left: 2px;
    }
    &:not(:last-child) {
      margin-right: 2px;
    }
    min-width: 16px;
    height: 16px;

    img {
      display: block;
    }

    &.primary {
      border-radius: 100%;
      background-color: var(--color6);
    }

    &.page {
      padding: 0 2px;
      min-width: 14px;
      height: 14px;
      border-radius: 1px;
      color: var(--color8);
      font-size: 12px;

      &.current {
        background-color: var(--color10);
        color: var(--color2);
      }
    }
  }
}

label.limit {
  text-decoration: underline;

  select {
    font-size: 12px;
    margin-left: 8px;
  }
}

@media (max-width: 550px) {
  label.limit {
    margin-left: 0px;

    select {
      margin-left: 2px;
    }
  }
}
